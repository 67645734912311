import React from "react";
import LazyLoad from "react-lazyload";

const Page15 = () => {
  return (
    <>
      <div className="whitepaper-page">
        <div
          style={{
            position: "absolute",
            width: 440,
            height: 260,
            left: 28,
            top: 30,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 12,
            lineHeight: "163.5%",
            textAlign: "justify",
            color: "rgba(47, 46, 65, 0.8)",
          }}
        >
          This prevents large-scale investors from purchasing 0xBlock tokens at
          reduced pricing before the general public has a chance to buy them.
          Inclusion of these rounds may suggest a risk of large-scale selloffs
          that affect short-term profitability and long-term project value.
          0xBlock is designed transparently to provide maximum benefits for the
          average user, and this feature enforces that.
          <br />
          <br />
          Prior to 0xB token launch, 0xBlock will mint 1 million 0xB tokens. The
          initial circulation of 0xB tokens will consist of 25,000 tokens for
          contract minting by 0xBlock early adopters. Circulation will increase
          over time as users receive more tokens from our Rewards Wallet in the
          form of contract returns.
        </div>
        <div
          style={{
            position: "absolute",
            width: 400,
            height: 20,
            left: 28,
            top: 305,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 12,
            lineHeight: "163.5%",
            textAlign: "justify",
            color: "rgba(47, 46, 65, 0.8)",
          }}
        >
          Initial distribution of tokens prior to the launch will be as follows:
        </div>
        <div
          style={{
            position: "absolute",
            width: 9,
            height: 9,
            left: 29,
            top: 336,
          }}
        >
          <LazyLoad>
            <img src="/images/Mark-Blue.svg"></img>
          </LazyLoad>
        </div>
        <div
          style={{
            position: "absolute",
            width: 419,
            height: 40,
            left: 47,
            top: 341,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 12,
            lineHeight: "163.5%",
            textAlign: "justify",
            color: "rgba(47, 46, 65, 0.8)",
          }}
        >
          <span style={{ color: "rgba(47, 46, 65, 1)" }}>Rewards Wallet</span> –
          70% of minted tokens to provide rewards to contract owners
        </div>
        <div
          style={{
            position: "absolute",
            width: 9,
            height: 9,
            left: 29,
            top: 397,
          }}
        >
          <LazyLoad>
            <img src="/images/Mark-Blue.svg"></img>
          </LazyLoad>
        </div>
        <div
          style={{
            position: "absolute",
            width: 420,
            height: 40,
            left: 47,
            top: 402,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 12,
            lineHeight: "163.5%",
            textAlign: "justify",
            color: "rgba(47, 46, 65, 0.8)",
          }}
        >
          <span style={{ color: "rgba(47, 46, 65, 1)" }}>Liquidity Wallet</span>{" "}
          – 10% of minted tokens to provide future liquidity to the pools of the
          DEXs on which the 0xB token will be listed or traded.
        </div>
        <div
          style={{
            position: "absolute",
            width: 9,
            height: 9,
            left: 29,
            top: 463,
          }}
        >
          <LazyLoad>
            <img src="/images/Mark-Blue.svg"></img>
          </LazyLoad>
        </div>
        <div
          style={{
            position: "absolute",
            width: 420,
            height: 60,
            left: 47,
            top: 468,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 12,
            lineHeight: "163.5%",
            textAlign: "justify",
            color: "rgba(47, 46, 65, 0.8)",
          }}
        >
          <span style={{ color: "rgba(47, 46, 65, 1)" }}>
            Development/Marketing Wallet
          </span>{" "}
          – 10% of minted tokens to support platform development and marketing
          costs to support ongoing growth.
        </div>
        <div
          style={{
            position: "absolute",
            width: 9,
            height: 9,
            left: 29,
            top: 544,
          }}
        >
          <LazyLoad>
            <img src="/images/Mark-Blue.svg"></img>
          </LazyLoad>
        </div>
        <div
          style={{
            position: "absolute",
            width: 419,
            height: 40,
            left: 47,
            top: 549,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 12,
            lineHeight: "163.5%",
            textAlign: "justify",
            color: "rgba(47, 46, 65, 0.8)",
          }}
        >
          <span style={{ color: "rgba(47, 46, 65, 1)" }}>
            Co-Founder Wallet
          </span>{" "}
          – 10% of minted tokens (with a 1-year vesting period) to be allocated
          to each co-founder (5% per co-founder).
        </div>
      </div>
    </>
  );
};

export default Page15;
